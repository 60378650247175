<script lang="ts" setup>
import type { GameLastWinner } from "@/types";

const { isSpecialWheel } = useSpecialWheelDecor();

const defaultTypesDictionary = new Map([
	["bigwin", "big win"],
	["jackpot", "jackpot"],
	["daily-wheel-accrual", isSpecialWheel.value ? "ocean{key}wheel" : "funrize{key}wheel"]
]);

const props = defineProps<{ winner: GameLastWinner }>();
defineEmits<{ (event: "click"): void }>();

const { t } = useT();
const {
	public: { baseImageUrl }
} = useRuntimeConfig();
const isGuest = useIsGuest();
const prepareImgUrl = useImage();

const isDailyWheelSpecial = computed(() => props.winner.type === "daily-wheel-accrual" && isSpecialWheel.value);

const imageSrcPath = computed(() => {
	if (defaultTypesDictionary.has(props.winner.type)) {
		return `/nuxt-img/activity-feed/${isDailyWheelSpecial.value ? "special-wheel" : props.winner.type}.jpg`;
	}

	return `${baseImageUrl}${props.winner.data?.logo}`;
});
</script>
<template>
	<div class="feed-card">
		<AAnimationSkeleton v-if="'skeleton' in winner" class="skeleton-wrapper">
			<ASkeleton width="100%" height="100%" />
		</AAnimationSkeleton>

		<template v-else>
			<MGameBadge v-if="winner.data.gameExclusiveData?.isExclusive" class="badge" variant="exclusive" />
			<div class="image-holder" @click="$emit('click')">
				<picture>
					<source
						:srcset="
							prepareImgUrl(imageSrcPath, {
								format: 'avif',
								width: 160,
								height: 200
							})
						"
						media="(max-width: 767px)"
					/>
					<NuxtImg
						:src="imageSrcPath"
						width="186"
						height="230"
						:alt="winner.type"
						:data-tid="winner?.data?.slug"
						format="avif"
					/>
				</picture>

				<AText class="label" variant="tampa" :modifiers="['bold', 'uppercase', 'center']" as="div">
					<i18n-t
						v-if="defaultTypesDictionary.has(winner.type)"
						:keypath="defaultTypesDictionary.get(winner.type) ?? ''"
					>
						<template #key><br /></template>
					</i18n-t>
					<div v-else v-html="winner.data?.title" />
				</AText>
			</div>

			<div class="content">
				<AText class="winner-name" data-tid="winner-name" :modifiers="['ellipsis', 'lowercase']">
					<b class="text-cannes">{{ winner.name || winner.nickname }} {{ winner.surname }}</b>
					<span>&nbsp;{{ t("Won") }}</span>
				</AText>

				<div class="prize-pool">
					<MPrizeFund
						v-if="winner.type === 'win' || winner.type === 'jackpot'"
						:variant="winner.gameMode === 'SweepStakes' && !isGuest ? 'entries' : 'coins'"
						:iconSize="20"
						:img="`/nuxt-img/cards/${winner.gameMode === 'SweepStakes' && !isGuest ? 'entries' : 'coins'}.png`"
					>
						<AText variant="turin" :data-tid="`${winner.type}-amount-${winner?.gameMode}`" :modifiers="['bold']">
							{{ numberFormat(winner.data?.winAmount || winner.data?.amount || 0) }}
						</AText>
					</MPrizeFund>

					<MPrizeFund v-if="winner.type === 'bigwin'" variant="coins" :iconSize="20" icon="20/big-win">
						<AText variant="turin" :data-tid="`bigwin-amount-${winner?.gameMode}`" :modifiers="['bold']">
							{{ numberFormat(winner.data.big_win_coefficient ?? 0) }}
						</AText>
					</MPrizeFund>

					<template v-if="winner.type === 'daily-wheel-accrual'">
						<MPrizeFund v-if="winner.data.coins" variant="coins" :iconSize="20" img="/nuxt-img/cards/coins.png">
							<AText variant="turin" :data-tid="`daily-wheel-amount-${winner?.gameMode}`" :modifiers="['bold']">
								{{ numberFormat(winner.data.coins) }}
							</AText>
						</MPrizeFund>

						<MPrizeFund
							v-if="winner.data?.entries && (!isGuest || !winner.data?.coins)"
							:variant="!isGuest ? 'entries' : 'coins'"
							:iconSize="20"
							:img="`/nuxt-img/cards/${!isGuest ? 'entries' : 'coins'}.png`"
						>
							<AText variant="turin" :data-tid="`daily-wheel-amount-${winner?.gameMode}`" :modifiers="['bold']">
								{{ numberFormat(winner.data.entries) }}
							</AText>
						</MPrizeFund>
					</template>
				</div>

				<AText v-if="winner.city || winner.state" variant="topeka" data-tid="winner-location">
					<NuxtIcon name="20/city" />
					{{ winner.city }}{{ winner.state ? `, ${winner.state.toUpperCase()}` : "" }}
				</AText>
			</div>
		</template>
	</div>
</template>
<style scoped lang="scss">
.feed-card {
	height: 326px;
	border-radius: 10px;
	background: var(--changshu);
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: hidden;

	@include media-breakpoint-down(md) {
		height: 288px;
	}

	.image-holder {
		width: 100%;
		height: 230px;
		position: relative;
		border-radius: 10px;
		overflow: hidden;
		cursor: pointer;

		@include media-breakpoint-down(md) {
			height: 200px;
		}

		img {
			position: absolute;
			top: 0;
			left: 0;

			@include media-breakpoint-down(md) {
				width: 160px;
				height: 200px;
			}
		}

		.label {
			position: absolute;
			bottom: 0;
			width: 100%;
			padding: 0 gutter(1) gutter(1);

			> div {
				display: flex;
				flex-direction: column;
			}

			&:deep(span) {
				font-size: 12px;
				font-weight: 400;
			}
		}
	}

	.content {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: gutter(1);
		padding: gutter(1.5) gutter(1);
		color: var(--conakry);
	}

	.winner-name b {
		text-transform: none;
	}

	.prize {
		gap: gutter(0.5);

		@include media-breakpoint-down(md) {
			span.turin {
				font-size: 14px;
			}

			&:deep(img) {
				width: 20px;
				height: 20px;
			}
		}

		&-pool {
			display: flex;
			gap: gutter(1);
		}
	}

	.badge {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 99;
	}
}
</style>
